import React, { Component } from 'react'

import Card from './Card'

import './Testimonial.scss'

class Testimonial extends Component {

  _toggle = e => {
    e.target.parentElement.classList.toggle('expand')
    this.more.classList.toggle('hide')
    this.less.classList.toggle('hide')
  }

  render () {
    const { text, name } = this.props
    return (
      <Card className='Testimonial'>
        {/*<img src={d.image} alt={`testimonial-${i}`} />*/}
        {text}
        <p className='Testimonial--name'>{name}</p>
        <div className='Testimonial--toggle more' ref={ref => this.more = ref} onClick={this._toggle}>Read more...</div>
        <div className='Testimonial--toggle less hide' ref={ref => this.less = ref} onClick={this._toggle}>Read less...</div>
      </Card>
    )
  }

}

export default Testimonial
