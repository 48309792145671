import React from 'react'

import Layout from '../components/Layout'
import Container from '../components/Container'
import Testimonial from '../components/Testimonial'

import { data } from '../assets/js/testimonials'

import './Testimonials.scss'

const Testimonials = ({ location }) =>
  <Layout
    location={location}
    title='Pawsome Dogs | Chicago Dog Training | Testimonials'
    description='Pawsome Dogs strives to provide a great training experience for you and your dog. Read testimonials from some of our clients.'>
    <main className='Testimonials'>

      <Container className='Testimonials--container'>

        <h2>What people are saying</h2>

        <div className='Testimonials--container--list'>
          {data.map((d, i) => <Testimonial key={i} text={d.text} name={d.name} />)}
          <div className='Testimonials--container--list--extra'></div>
        </div>

      </Container>

    </main>
  </Layout>

export default Testimonials
