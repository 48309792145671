import React, { Fragment } from 'react'

import testimonial1Image from '../../assets/img/testimonials/testimonial-1.png'
import testimonial4Image from '../../assets/img/testimonials/testimonial-4.png'
import testimonial6Image from '../../assets/img/testimonials/testimonial-6.png'
import quoteOpenImage from '../../assets/img/testimonials/quotation-marks-open.svg'
import quoteCloseImage from '../../assets/img/testimonials/quotation-marks-close.svg'

export const data = [
  {
    source: null,
    image: testimonial4Image,
    name: '- Erika A.',
    text: (
      <Fragment>
        <p><img className='quote' src={quoteOpenImage} alt='quote open' />&nbsp;&nbsp;
          Melissa is a life saver and has worked
          wonders with our pup! I contacted Pawsome Dogs after previously
          working with a different trainer who I felt didn't genuinely
          care about my concerns or the needs of my dog and thank god I did!</p><br />
        <p>Melissa dedicates her time to you and your dog, genuinely cares
          and goes above and beyond in every lesson we have had with her - I
          have never once felt rushed by her and she has always taken the
          time to answer our questions.</p><br />
        <p>Our dog had some major issues going on including resource guarding
          the couch, being afraid of strangers, fearful aggression, etc... I
          started to see positive results after just a few sessions with
          Melissa. We have done in home lessons, met at dog parks, in busy
          shopping areas and has even met us at a doggie daycare to ensure
          we felt comfortable leaving our pup in a strange place. She cares
          about each individual dogs needs and will tailor your lessons to work
          on things that are important to you and your dog.
          &nbsp;&nbsp;<img className='quote' src={quoteCloseImage} alt='quote close' /></p>
      </Fragment>
    ),
  },
  {
    source: null,
    image: testimonial4Image,
    name: '- Jillian A. & Shaun G.',
    text: (
      <Fragment>
        <p><img className='quote' src={quoteOpenImage} alt='quote open' />&nbsp;&nbsp;
          Melissa is amazing! She's currently helping us train our 2 year
          old, rescue, Boston Terrier named Bosley. He was shuffled around a lot
          before we adopted him and had some trust and anxiety issues. He would
          lunge at people on walks and did not like it when people left or entered
          our house. Melissa has helped us learn the correct techniques to walk
          him, including great tips on products to buy. She has also worked with
          him on meeting new people. Bosley loves his training sessions and Melissa. He
          is eager to learn from her and so are we. He is so much better behaved
          after only a few lessons and we couldn't be happier!
          &nbsp;&nbsp;<img className='quote' src={quoteCloseImage} alt='quote close' /></p>
      </Fragment>
    ),
  }, {
    source: null,
    image: testimonial6Image,
    name: '- Steph K.',
    text: (
      <Fragment>
        <p><img className='quote' src={quoteOpenImage} alt='quote open' />&nbsp;&nbsp;
          Melissa is a fantastic trainer! You can tell she really loves
          animals and her job. She's a great teacher and very professional. Our
          German Shepherd loves her and listens very well with her! We highly
          recommend Melissa at Pawsome Dogs!
          &nbsp;&nbsp;<img className='quote' src={quoteCloseImage} alt='quote close' /></p>
      </Fragment>
    ),
  }, {
    source: 'Google Review',
    image: null,
    name: '- Caitie R.',
    text: (
      <Fragment>
        <p><img className='quote' src={quoteOpenImage} alt='quote open' />&nbsp;&nbsp;
          Melissa is the BEST! I just moved into the city with my 1 yr
          old labradoodle who was terrified of everything in Chicago; she was
          so fearful and reactive on our walks and it got to the point where I
          dreaded walking her. Melissa did an evaluation of my pup and then after
          just a few lessons, helped me to manage her behaviors. I can now walk
          and even run with my dog without worrying about her barking and lunging
          at everything we cross paths with! Melissa was always prompt and
          professional and provided me with written instructions of everything
          we covered during our lessons. I would highly recommend Melissa for
          all of your dog behavior and training needs!
          &nbsp;&nbsp;<img className='quote' src={quoteCloseImage} alt='quote close' /></p>
      </Fragment>
    ),
  }, {
    source: 'Yelp Review',
    image: null,
    name: '- Casey M.',
    text: (
      <Fragment>
        <p><img className='quote' src={quoteOpenImage} alt='quote open' />&nbsp;&nbsp;
          Melissa knows her stuff! My dog loved her and responded to her
          immediately, I'll admit it, I was jealous that she could make my dog
          listen and I couldn't! Haha! She is professional, patient, and brings
          with her an arsenal of dog goodies so you have lots of options to figure
          out what works for your pooch. Our pup showed immense improvement from
          day one. My only regret is not hiring her sooner, I feel like we could
          have saved our couch and 3 office chairs from the wrath of our hyper
          active dog who has separation anxiety. We haven't lost any more furniture
          since Melissa came into our lives. She's worth every penny, we had her
          for 5 private sessions, she left us feeling confident that we could continue '
          practicing all that we learned with her and get our dog to his best.
          &nbsp;&nbsp;<img className='quote' src={quoteCloseImage} alt='quote close' /></p>
      </Fragment>
    ),
  }, {
    source: null,
    image: testimonial1Image,
    name: '- Laura J.',
    text: (
      <Fragment>
        <p><img className='quote' src={quoteOpenImage} alt='quote open' />&nbsp;&nbsp;
          Melissa is an amazing trainer who is committed to success
          with her clients! We enrolled Margaux in her beyond basics class
          after attending a beginners class with another popular trainer in
          Lincoln Park - we felt that Melissa's class and overall approach
          was more effective, and Margaux has learned and solidified so many
          commands. She even learned a few tricks! We will definitely be
          using Melissa to continue her training in the future.
          &nbsp;&nbsp;<img className='quote' src={quoteCloseImage} alt='quote close' /></p>
      </Fragment>
    ),
  },
]
